body {
  background: $backgroundColor;
}

// Logo

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// base

.container {
  max-width: 1400px;
  margin: 0 auto;
  // border: 1px solid rgba(255,255,255,.1);
  @include padding(2rem);
}

.chart-title-container {
  text-align: center;
  @include margin-top(1rem);

  h2 {
    display: block;
    @include margin-bottom(0.3rem);
  }

  .chart-title-buttons {
    @include padding(0.5rem);
    display: flex;
    justify-content: center;

    .btn {
      color: black;
      background: grey;
      @include font-size(0.7rem);
      @include padding-left(0.7rem);
      @include padding-right(0.7rem);
      @include padding-top(0.3rem);
      @include padding-bottom(0.3rem);
      @include margin(0.2rem);
    }
  }
}

.graphics-container {
  display: flex;
  flex-direction: column;
  .chart-container,
  .table-container {
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.table-container {
  @include margin-top(2rem);
}

.home-container {
  @include margin-top(2rem);
  .app-header {
    display: flex;
    // hei/ght: inherit;
    align-items: stretch;
    justify-content: center;
    margin: 0 auto;
    .header-logo {
      width: 4rem;
      @include margin-right(1rem);
    }
    .header-titles {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      // align-items: center;
      // height/: 100%;
      h1 {
        @include margin-bottom(0.1rem);
        @include font-size(2rem);
      }
      h3 {
        opacity: 0.6;
        @include font-size(0.85rem);
      }
    }
  }
}

.input-container {
  max-width: 500px;
  margin: 2rem auto;
}

.input-select {
  @include font-size(0.6rem);
  // font-size: .35rem;
}

.btn {
  background: #7bff73;
  color: black;
  @include padding(0.6rem);
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
  }
}

@media (min-width: 1025px) {
  .container {
    @include padding(1rem);
  }

  .table-container {
    margin: 0;
    @include padding-left(1rem);
  }

  .graphics-container {
    flex-direction: row;
    .chart-container,
    .table-container {
      width: 50%;
    }
  }
}

@media (min-width: 725px) {
  .input-select {
    @include font-size(1rem);
    // font-size: .35rem;
  }
}

table {
  @include font-size(0.8rem);
  th {
    text-align: left;
    @include padding(0.4rem);
  }

  td {
    @include padding(0.4rem);
  }
  tbody {
    tr {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.responsiveTable {
  tbody {
    tr {
      border: none;
    }
  }
}
