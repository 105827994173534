html {
  color: $textColor;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @include font-size(2rem);
}

h2 {
  @include font-size(1.5rem);
}

h3 {
  @include font-size(1rem);
}

h4 {
  @include font-size(0.8rem);
}
