.input-row {
  display: flex;
  justify-content: space-between;
  @include margin-bottom(1rem);
  &.submit-row {
    justify-content: center;
  }
}

.input-row > .input-select {
  width: 31%;
}

.full-row > div {
  width: 100%;
}

input {
  background: $bodyBackgroundColor;
  color: $textColor;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: .58rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $textColor;
  opacity: .7; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $textColor;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $textColor;
}

.places-input-container {
  position: relative;
}

.autocomplete-dropdown-container {
  background: $bodyBackgroundColor;
  color: $textColor;
  position: absolute;
  top: 0;
  z-index: 1;
  margin-top: 2.4rem;
  width: 100%;
}

.suggestion-item, .suggestion-item--active {
  padding: .5rem;
}
